@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

li .autocomplete-item{
	font-size:14px;
	height: auto;
	line-height: 1.2;
	border-bottom: 1px solid rgba(151, 151, 151, 0.34)
}
button:focus {
  outline: none;
}

a:focus {
  outline: none;
}

#footer fieldset {
  border-color: #eee;
}

.control fieldset {
  border-radius: 10px;
  border-color: rgb(234, 234, 234);
  width: 190px;
  background-color: rgb(248, 248, 248);
}
@font-face {
  font-family: Rambla;
  font-weight: 400;
  src: url(/static/media/Rambla-Regular.8fd8bd4a.otf);
}

@font-face {
  font-family: Rambla;
  src: url(/static/media/Rambla-Bold.39f4b31c.otf);
  font-weight: 700;
}

@font-face {
  font-family: Open Sans;
  src: url(/static/media/OpenSans-Regular.3ed9575d.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Open Sans;
  src: url(/static/media/OpenSans-Bold.1025a6e0.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Open Sans;
  src: url(/static/media/OpenSans-SemiBold.ba5cde21.ttf);
  font-weight: 600;
}

@font-face {
  font-family: IBMPlex;
  src: url(/static/media/IBMPlexMono-Regular.cb46f1f1.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Roboto Mono;
  src: url(/static/media/RobotoMono-Regular.e5ca8c0a.ttf);
  font-weight: 400;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    /*background-color: #F6F6F6;*/
}

.navbar {
    background-color: #001E35;
}
.navbar-brand {
    font-size: 26px;
}

::-webkit-scrollbar {
    display: none;
}

#jumbotron_top {
    background-image: url(/static/media/header_top.f41e4ee4.jpg);
    background-repeat: no-repeat;
    background-position: 50% 60%;
    background-attachment: scroll;
    min-height: 300px;
}
.mask {
    opacity: 0.6;
    background-color: #001E35;
    min-height: inherit;
}
#div_header {
    top: 6rem;
    left: 0;
    right: 0;
}
.sub-headers {
    font-family:'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}
.bottom-margin {
    margin-bottom: 3%;
}
table {
    /*    table-layout: fixed;*/
}

.footer{
    background-color: #001E35;
}

#jumbotron_top h4{
    font-family:'Poppins', sans-serif;
    font-weight: 900;
}
#jumbotron_top h5{
    font-family:'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
}
.h5 {
    font-family:'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
}
#jumbotron_top h1{
    font-family:'Poppins', sans-serif;
    font-weight: bold;
    font-size: 48px;
}
#input_email, #button_submit{
    border-radius: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #D4D4D4;
}
#button_submit{
    background-color: #FF9700;
    color: #FFFFFF;
}

select {
    -moz-appearance:none;
}
.subtext {
    font-size: 10px;
    color: #7C7C7C;
    font-weight: normal;
}
.col {
    font-size: 14px;
    font-weight: normal;
}
.padding-top {
    padding-top: 0.5rem;
}
.padding-bottom {
    padding-bottom: 0.5rem;
}
.neighborhood-name {
    margin-bottom: 0px;
    margin-top: 8px;
}
.table td{
    vertical-align: bottom;
}

.location-logo{
    float: left;
    height: 25px;
    width: 25px;
    margin-right: 15px;
}



.no-left-padding {
    padding-left: 0px;
}


.top-margin {
    margin-top: 3%;
}
.inline {
    display: inline-block;
}
ul {
    list-style-type: none;
    padding: 0;
    padding-left: 30px;
    font-family:'Poppins', sans-serif;
    font-size: 20px;
}

#search-container h3{
    font-family:'Poppins', sans-serif;
    font-weight: 900;
    font-size: 40px;
    overflow: scroll;
    background-color: red;
}

.row #subTitleChart {
    display: block;
    padding: 15px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

.row #crimeRank {
    display: block;
    padding: 15px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

.row #info1 {
    display: block;
    padding: 15px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

.row #error {
    display: block;
    padding: 15px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

::-webkit-input-placeholder {
    text-align: center;
}
.has-search .form-control {
    padding-left: 2.375rem;
    /*margin-top: 10px;*/

&:focus {
     outline: none;
     box-shadow: none;
     border-color: #FF9700;
 }
}

.fa .fa-search {
    text-align: center;
}

.faStyle {
    padding: 15px;
    text-align: center;
    font-size: 35px !important;
}

#crimeRank {
    clear: both;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 20px;
    position: relative;
}

#rank {
    float: left;
}

#total {
    float: left;
}

#subTitleRank h3{
    padding: 15px;

}

#crimeRank #rank {
    color: #FF9700;
    font-size: 100px;
    font-family:'Poppins', sans-serif;
    font-weight: bold;
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;
}

#crimeRank #total {
    font-size: 30px;
    color:  #d3d3d3;
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;
}

#rankContent {
    padding: 30px;
    height: auto;
    margin: 0 auto;
    position: relative;
}

#rankData {
    position: relative;
}

#rankTable {
    /*margin-top: 50px;*/
}

.center {
    text-align: center;
}

.month-chart {
    margin-right: 90px;
}
.change-in-rank {
    align: left;
}


.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
.form-control {
    border-radius: 25rem;
    text-align: center;
    font-size: 14px;
    color: #ADADAD;
}
.toggle-text {
    color: #7C7C7C;
}
.about-data-text {
    color: #7C7C7C;
}
.crime-text {
    font-size: 12px;
}


#ui-id-1 {
    border-radius: 10px;
    background-color: white;
    width: 40px;
    height: 150px;
    overflow-y: scroll;
}

#ui-id-1 div:focus, #ui-id-1 div:hover, #ui-id-1 div:active {
    background-color: #FF9700;
}

/*icon*/
.fa-twitter{
    color:white;
    font-size: 25px;
}
/*footer*/
#footer-copyright{
    color: rgba(255,255,255,0.5);
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
}
/*text links*/
.text-link{
    color:#FF9700;
}

thead {
    /*background-color: #FF9700;*/
    text-align: center;
}
.table thead th {
    font-weight: normal;
}



/* arrows */
.oi-caret-top {
    color:#FF9700;
}
.oi-caret-bottom {
    color:#071C31;
}



/*loading spinner*/
.loader_mask {
    position: fixed;
    z-index:1000;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.7;
}
.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


/*--------------------------------------------------------------
## Responsive screen
--------------------------------------------------------------*/
@media (max-width: 992px) { /* screen size until 800px */
    #div_header > h1{
        font-size: 35px; /* 0.7x default size */
    }
    #jumbotron_top {
        min-height: 350px;
    }
    #footer-copyright {
        width:100%;
        float:inherit;
    }
}

@media (max-width: 768px) { /* screen size until 600px */
    #div_header > h1{
        font-size: 30px; /* 0.8x default size */
    }
    #jumbotron_top {
        min-height: 400px;
    }
    #footer-copyright {
        width:100%;
        float:inherit;
    }
    #neighborhood-name {
        font-size: 1rem;
    }
}

/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/
.main-navigation {
    clear: both;
    display: inline-block;
    float: right;
    padding-top: 4px;
    /*width: 100%;*/
}


.main-navigation .ct-mobile-menu-list-icon{
    display: none;
}
.main-navigation ul {
    display: none;
    list-style: none;
    margin: 0;
    padding-left: 0;
    height: 62px;
    line-height: 62px;

}

.main-navigation li.current-menu-item a{
    border-bottom: 4px solid #FF9700;
}


.main-navigation a {
    display: block;
    color: #fff;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    line-height: 62px;
}

.main-navigation a:visited {
    color: #fff;
}

.main-navigation a:hover {
    /*transition: all linear 1s;*/
    color: #FF9700;
    border-bottom: 4px solid #FF9700;
}

.main-navigation ul ul {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    float: left;
    position: absolute;
    top: 100%;
    left: -999em;
    z-index: 99999;
}

.main-navigation ul ul ul {
    left: -999em;
    top: 0;
}

.main-navigation ul ul li:hover > ul,
.main-navigation ul ul li.focus > ul {
    left: 100%;
}

.main-navigation ul ul a {
    color: #000;
    width: 200px;
}
.main-navigation ul ul a:visited {
    color: #000;
}
.main-navigation ul li:hover > ul,
.main-navigation ul li.focus > ul {
    left: auto;
}

.main-navigation li {
    float: left;
    position: relative;
    padding-left: 25px;
    padding-right: 25px;
}
.main-navigation li .nbsp{
    display: none;
}
.main-navigation .sub-menu{
    background: #fff;
    z-index: 9999;
}
.main-navigation .sub-menu li a{
    border-bottom: none;
}
.main-navigation .sub-menu li{
    background: #fff;
}
.main-navigation .sub-menu a:hover {
    /*transition: all linear 1s;*/
    border-bottom: none;
}
.main-navigation .sub-menu li:hover {
    border-bottom:  4px solid #FF9700;
}
.main-navigation .sub-menu li.current-menu-item a{
    border-bottom: none;
}
.main-navigation .sub-menu li.current-menu-item{
    border-bottom: 4px solid #FF9700;
}


/* Small menu. */
.menu-toggle,
.main-navigation.toggled ul {
    display: inline-block;
    float: right;
    color: #fff;
    line-height: 70px;
    font-size: 28px;
    margin-right: 4px;
    padding: 0 12px;
    background-color: transparent;
    border: none;
    outline:none;
}


/*.ct-mobile-menu.open-mobile-menu{
	background-image: url('./images/menu.png');
}
.ct-mobile-menu.close-mobile-menu{
	background-image: url('./images/close.png');
}
*/
@media screen and (min-width: 75em) {
    .menu-toggle {
        display: none;
    }
    .main-navigation ul {
        display: block;
    }
}

/*Poppins*/

.ui-widget {
    font-family: Poppins, Trebuchet MS,Tahoma,Verdana,Arial,sans-serif;
    font-size: 1.1em;
}
.TriSea-technologies-Switch > input[type="checkbox"] {
    display: none;   
}

.TriSea-technologies-Switch > label {
    cursor: pointer;
    height: 0px;
    position: relative; 
    width: 40px;  
}

.TriSea-technologies-Switch > label::before {
    background: rgb(0, 0, 0);
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    content: '';
    height: 12px;
    margin-top: -4px;
    position:absolute;
    opacity: 0.3;
    transition: all 0.4s ease-in-out;
    width: 30px;
}
.TriSea-technologies-Switch > label::after {
    background: rgb(255, 255, 255);
    border-radius: 12px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 20px;
    left: -4px;
    margin-top: -4px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 20px;
}
.TriSea-technologies-Switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 0.5;
}
.TriSea-technologies-Switch > input[type="checkbox"]:checked + label::after {
    background: white;
    left: 20px;
}
#gaf-button,#gaf-dialog{z-index:99999;box-sizing:border-box;position:fixed}#gaf-button,#gaf-dialog,#gaf-dialog *{box-sizing:border-box}#gaf-button,#gaf-dialog a,#gaf-submit{text-decoration:none}#gaf-button{bottom:50px;background:#3D95D0;color:#fff;font-family:Roboto;padding:4px 7px;font-size:12px;border-top-left-radius:5px;border-top-right-radius:5px}#gaf-dialog{border-radius:8px;font-size:16px;font-family:Roboto,sans-serif;font-weight:300;top:20%;left:25%;width:50%;background:#FFF;box-shadow:0 0 25px #aaa;padding:30px}#gaf-dialog a{outline:0}#gaf-dialog h5{text-align:center;font-weight:500;font-size:16px;margin:0 auto 12px}.gaf-text-label{font-size:14px;color:#3C3C3C;margin:16px auto 0;display:block}#gaf-dialog .gaf-text{display:inline-block;margin-top:10px;padding:10px;background:#fff;font-size:15px;border:1px solid #E6E6E6;border-radius:8px;outline:0;text-align:left;width:100%}#gaf-submit{color:#FFF;font-size:17px;text-align:center;display:block;font-weight:700;padding:6px 20px;margin:20px auto 0;max-width:200px;background:#3D95D0;border-radius:8px}#gaf-submit:hover{opacity:.7}#gaf-dialog-close{position:fixed;top:19%;right:25%;padding:10px;font-size:24px;color:rgba(0,0,0,.3);line-height:1}@media only screen and (max-width:800px){#gaf-dialog{left:10%;width:80%}#gaf-dialog-close{right:10%}}
::-webkit-input-placeholder {
    color: #b4b4b4;
    text-align: left;
}

.blooming-menu__container {
    width: 40px !important;
    height: 40px !important;
}

.blooming-menu__main {
    background-color: #ffffff !important;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
    border-radius: 0;
    width: 40px !important;
    height: 40px !important;
    padding: 2px 2px 0 0 !important;
}

.blooming-menu__item {
    width: 32px !important;
    height: 32px !important;
}

.blooming-menu__main-content {
    background: url("/images/share.svg") center no-repeat;
    background-size: 25px;
}

.blooming-menu__item-btn-wrapper {
    background-color: #123456 !important;
}

.blooming-menu__item:nth-of-type(1) .blooming-menu__item-btn {
    background-image: url(/images/share-facebook.svg);
}

.blooming-menu__item:nth-of-type(2) .blooming-menu__item-btn {
    background-image: url(/images/share-twitter.svg);
}

.blooming-menu__item:nth-of-type(3) .blooming-menu__item-btn {
    background-image: url(/images/share-linkedin.svg);
}

.blooming-menu__item:nth-of-type(4) .blooming-menu__item-btn {
    background-image: url(/images/share-wechat.svg);
}

.blooming-menu__item:nth-of-type(5) .blooming-menu__item-btn {
    background-image: url(/images/share-paste.svg);
}

.blooming-menu__item-btn:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2);
    opacity: 1;
}

.bm-tooltip {
    z-index: 10000;
    height: 0px;
    pointer-events: auto;
}

.bm-tooltip-top {
    color: #fff;
    height: 0px;
    opacity: 0;
    padding: 4px 8px;
    transform: scale(0);
    font-size: 0.625rem;
    transition: height 300ms, opacity 150ms cubic-bezier(0.4, 0, 1, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 1, 1) 0ms;
    min-height: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.4em;
    border-radius: 4px;
    background-color: #616161;
}

.bm-tooltip-left {
    margin: 0 14px;
    transform-origin: right center;
    color: #fff;
    height: 0px;
    opacity: 0;
    padding: 4px 8px;
    transform: scale(0);
    font-size: 0.625rem;
    transition: height 300ms, opacity 150ms cubic-bezier(0.4, 0, 1, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 1, 1) 0ms;
    min-height: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.4em;
    border-radius: 4px;
    background-color: #616161;
}

.bm-tooltip-top.bm-tooltip-active {
    height: auto;
    opacity: 0.9;
    transform: scale(1);
    transition: height 300ms, opacity 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.bm-tooltip-left.bm-tooltip-active {
    height: 22px;
    opacity: 0.9;
    transform: scale(1);
    transition: height 300ms, opacity 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

#gaf-button {
    position: fixed;
    transform: rotate3d(0, 0, 1, -90deg);
    bottom: 50px;
    right: -25px;
    background: #3D95D0;
    color: #fff;
    font-family: Roboto;
    padding: 4px 7px;
    font-size: 12px;
    text-decoration: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 99999;
    box-sizing: border-box;
}

#gaf-dialog {
    /* Rectangle 2: */
    border-radius: 8px;
    font-size: 16px;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    position: fixed;
    top: 20%;
    left: 25%;
    width: 50%;
    background: #FFFFFF;
    box-shadow: 0 0 25px #aaa;
    padding: 30px;
    z-index: 99999;
    box-sizing: border-box;
}

#gaf-dialog a {
    text-decoration: none;
    outline: none;
}

#gaf-dialog * {
    box-sizing: border-box;
}

#gaf-dialog h5 {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    margin: 0 auto 12px;
}

.gaf-text-label {
    /* Please enter your su: */
    font-size: 14px;
    color: #3C3C3C;
    margin: 16px auto 0px;
    display: block;
}

#gaf-dialog .gaf-text {
    display: inline-block;
    margin-top: 10px;
    padding: 10px;
    background: #fff;
    font-size: 15px;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    outline: none;
    text-align: left;
    width: 100%;
}

#gaf-submit {
    color: #FFF;
    font-size: 17px;
    text-decoration: none;
    text-align: center;
    display: block;
    font-weight: 700;
    padding: 6px 20px;
    margin: 20px auto 0;
    max-width: 200px;
    background: #3D95D0;
    border-radius: 8px;
}

#gaf-submit:hover {
    opacity: .7
}

#gaf-dialog-close {
    position: fixed;
    top: 19%;
    right: 25%;
    padding: 10px;
    font-size: 24px;
    color: rgba(0, 0, 0, .3);
    line-height: 1
}

@media only screen and (max-width: 800px) {

    #gaf-dialog {
        left: 10%;
        width: 80%
    }

    #gaf-dialog-close {
        right: 10%
    }
}
