.blooming-menu__container {
    width: 40px !important;
    height: 40px !important;
}

.blooming-menu__main {
    background-color: #ffffff !important;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
    border-radius: 0;
    width: 40px !important;
    height: 40px !important;
    padding: 2px 2px 0 0 !important;
}

.blooming-menu__item {
    width: 32px !important;
    height: 32px !important;
}

.blooming-menu__main-content {
    background: url("/images/share.svg") center no-repeat;
    background-size: 25px;
}

.blooming-menu__item-btn-wrapper {
    background-color: #123456 !important;
}

.blooming-menu__item:nth-of-type(1) .blooming-menu__item-btn {
    background-image: url(/images/share-facebook.svg);
}

.blooming-menu__item:nth-of-type(2) .blooming-menu__item-btn {
    background-image: url(/images/share-twitter.svg);
}

.blooming-menu__item:nth-of-type(3) .blooming-menu__item-btn {
    background-image: url(/images/share-linkedin.svg);
}

.blooming-menu__item:nth-of-type(4) .blooming-menu__item-btn {
    background-image: url(/images/share-wechat.svg);
}

.blooming-menu__item:nth-of-type(5) .blooming-menu__item-btn {
    background-image: url(/images/share-paste.svg);
}

.blooming-menu__item-btn:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2);
    opacity: 1;
}

.bm-tooltip {
    z-index: 10000;
    height: 0px;
    pointer-events: auto;
}

.bm-tooltip-top {
    color: #fff;
    height: 0px;
    opacity: 0;
    padding: 4px 8px;
    transform: scale(0);
    font-size: 0.625rem;
    transition: height 300ms, opacity 150ms cubic-bezier(0.4, 0, 1, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 1, 1) 0ms;
    min-height: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.4em;
    border-radius: 4px;
    background-color: #616161;
}

.bm-tooltip-left {
    margin: 0 14px;
    transform-origin: right center;
    color: #fff;
    height: 0px;
    opacity: 0;
    padding: 4px 8px;
    transform: scale(0);
    font-size: 0.625rem;
    transition: height 300ms, opacity 150ms cubic-bezier(0.4, 0, 1, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 1, 1) 0ms;
    min-height: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.4em;
    border-radius: 4px;
    background-color: #616161;
}

.bm-tooltip-top.bm-tooltip-active {
    height: auto;
    opacity: 0.9;
    transform: scale(1);
    transition: height 300ms, opacity 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.bm-tooltip-left.bm-tooltip-active {
    height: 22px;
    opacity: 0.9;
    transform: scale(1);
    transition: height 300ms, opacity 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

#gaf-button {
    position: fixed;
    transform: rotate3d(0, 0, 1, -90deg);
    bottom: 50px;
    right: -25px;
    background: #3D95D0;
    color: #fff;
    font-family: Roboto;
    padding: 4px 7px;
    font-size: 12px;
    text-decoration: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 99999;
    box-sizing: border-box;
}

#gaf-dialog {
    /* Rectangle 2: */
    border-radius: 8px;
    font-size: 16px;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    position: fixed;
    top: 20%;
    left: 25%;
    width: 50%;
    background: #FFFFFF;
    box-shadow: 0 0 25px #aaa;
    padding: 30px;
    z-index: 99999;
    box-sizing: border-box;
}

#gaf-dialog a {
    text-decoration: none;
    outline: none;
}

#gaf-dialog * {
    box-sizing: border-box;
}

#gaf-dialog h5 {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    margin: 0 auto 12px;
}

.gaf-text-label {
    /* Please enter your su: */
    font-size: 14px;
    color: #3C3C3C;
    margin: 16px auto 0px;
    display: block;
}

#gaf-dialog .gaf-text {
    display: inline-block;
    margin-top: 10px;
    padding: 10px;
    background: #fff;
    font-size: 15px;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    outline: none;
    text-align: left;
    width: 100%;
}

#gaf-submit {
    color: #FFF;
    font-size: 17px;
    text-decoration: none;
    text-align: center;
    display: block;
    font-weight: 700;
    padding: 6px 20px;
    margin: 20px auto 0;
    max-width: 200px;
    background: #3D95D0;
    border-radius: 8px;
}

#gaf-submit:hover {
    opacity: .7
}

#gaf-dialog-close {
    position: fixed;
    top: 19%;
    right: 25%;
    padding: 10px;
    font-size: 24px;
    color: rgba(0, 0, 0, .3);
    line-height: 1
}

@media only screen and (max-width: 800px) {

    #gaf-dialog {
        left: 10%;
        width: 80%
    }

    #gaf-dialog-close {
        right: 10%
    }
}