button:focus {
  outline: none;
}

a:focus {
  outline: none;
}

#footer fieldset {
  border-color: #eee;
}

.control fieldset {
  border-radius: 10px;
  border-color: rgb(234, 234, 234);
  width: 190px;
  background-color: rgb(248, 248, 248);
}
@font-face {
  font-family: Rambla;
  font-weight: 400;
  src: url("../../../fonts/Rambla-Regular.otf");
}

@font-face {
  font-family: Rambla;
  src: url("../../../fonts/Rambla-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: Open Sans;
  src: url("../../../fonts/OpenSans-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Open Sans;
  src: url("../../../fonts/OpenSans-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Open Sans;
  src: url("../../../fonts/OpenSans-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: IBMPlex;
  src: url("../../../fonts/IBMPlexMono-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Roboto Mono;
  src: url("../../../fonts/RobotoMono-Regular.ttf");
  font-weight: 400;
}
